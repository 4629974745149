.w-segmentation .geo {
  .step-list {
    font-size: .857rem;

    .minus,
    .plus {
      font-size: .857rem;
      width: 1.2em;
      height: 1.2em;
      line-height: 1.2em;
      padding: 0 0.1em;
      border-radius: 50%;
      background: rgba(97, 96, 196, 0.15);
      text-align: center;
      cursor: pointer;
    }

    .add {
      display: flex;
      flex: 1 1 0;
      margin-top: 1em;
      font-size: .857rem;
      height: 1.2em;
      line-height: 1.2em;
      cursor: pointer;
      & > span {
        margin-left: .5em;
      }
    }

    .vs-list  {
      padding-top: 0;
      padding-bottom: 0;
      border: 1px solid #AEA9A9;
      border-radius: 5px;
      margin-top: .5em;
      .vs-list--item {
        max-width: 100%;
        .list-titles {
          max-width: calc(100% - 1.2em);
          .vs-list--subtitle {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

  }

  .vue-map {
    height: 100%;
  }
}
