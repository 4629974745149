











































































































.vs-con-input-label.is-label-placeholder {
  margin-top: 0;
}
