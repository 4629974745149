/*=========================================================================================
  File Name: main.scss
  Description: Main scss file. Imports other scss partials from 'vuexy' folder
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import '~bootstrap';
@import '~bootstrap-vue';
@import "../css/tailwind.css";
@import "vuexy/variables";

@import "vuexy/layout";
@import "vuexy/typography";
@import "vuexy/misc";
@import "vuexy/extraComponents";
@import "vuexy/themes";
@import "vuexy/transitions";

@import "vuexy/customClasses";

// Vuesax fixes and enhancments
@import "vuexy/fixesVuesax";

// Tailwind Fixes
@import "vuexy/tailwindFix";
@import "segmentation/segmentation";
@import "segmentation/geolocation";


.vx-navbar-wrapper.navbar-default {
  z-index: 1039;
}

.vs-button {
  border-radius: 0.4285rem;
}

.modal-body {
  padding: 1rem;
}
.vs-con-input-label {
  width: 100%;
  & .vs-input--placeholder {
    font-size: 1.1rem;
  }
  & .vs-input--icon {
    font-size: 1.5rem;
  }
  .vs-input--input.hasValue + .vs-placeholder-label,
  .vs-input--input:focus + .vs-placeholder-label {
    font-size: 0.7rem;
  }
}

.divider {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin: 1rem 0;
  & .divider-text {
    position: relative;
    display: inline-block;
    font-size: 0.9375rem;
    padding: 0 1rem;
    background-color: #fff;
  }
  & .divider-text:before {
    right: 100%;
  }
  & .divider-text:after {
    left: 100%;
  }
  & .divider-text:after,
  & .divider-text:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.vs-con-input-label.is-label-placeholder {
  margin-top: 1.1em;
}

.modal-content {
  border-radius: 0.6rem;
}
.vs-input--input.large {
  border-radius: 6px;
}

.vuesax-app-is-ltr .vs-input--input.hasValue + .vs-placeholder-label,
.vuesax-app-is-ltr .vs-input--input:focus + .vs-placeholder-label {
  padding-left: 0.7rem !important;
}

.router-view,
.content-wrapper,
.router-content,
.content-area__content {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: stretch;
}

.content-wrapper {
  padding-top: 1.8rem;
}
.router-view {
  padding-top: 5.5rem;
}

.navbar-floating .router-content {
  margin-top: 0;
}

.footer-sticky .router-view {
  padding-bottom: 0;
}

@media (min-width: 577px){
  .navbar-floating .vx-navbar-wrapper {
    padding-bottom: 0;
    height: calc(103px - 2.2rem);
  }
}
