.w-segmentation {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 5px;
  background: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  margin-right: 1rem;
  padding: 0 2.5rem 1rem;
  overflow: auto;
  min-width: 450px;
  max-width: 100%;

  color: #808080;

  & .step-title-icon {
    position: absolute;
    left: 0;
  }

  & .step-title {
    font-size: 1.143rem;
    color: black;
    line-height: 1.25em;
    font-weight: 500;
  }

  & .step-subtitle {
    font-size: 1rem;
    color: #0F1932;
    font-weight: 500;
    line-height: 1.214em;
    padding-top: 35px;
  }

  & .step-description {
    font-size: 0.857rem;
    font-weight: 300;
    padding-bottom: 0.83333333333333333333333333333333em;
  }

  & .step-wrapper {
    font-size: 1rem;
    font-weight: normal;
    flex-grow: 1;
    padding-left: 1.786rem;
    position: relative;
  }

  & .step-content {
    font-size: 1rem;
    font-weight: normal;
    flex-grow: 1;

    & > .step-content,
    & > .step-description {
      padding-left: 1.5em;
      max-width: 100%;
    }

    & > .step-description {
      padding: .72em 0 0;
    }

    label {
      font-size: 0.857rem;
      font-weight: 300;
    }

    & .step-title-icon {
      position: absolute;
      left: 0px;
      & svg {
        width: 16px;
        height: 20px;
        color: #E61E62;
      }
    }
  }

  .input {
    font-size: 0.857rem;
    line-height: 1.25em;
    width: 100%;
    position: relative;

    input[type="text"],
    input[type="number"],
    select {
      font-size: 1em;
      line-height: 1.25em;
      width: 100%;
      border-radius: 3px;
      border: 1px solid #AEA9A9;
      padding: 0em 2em 0 1.25em;
      background-color: white;
      height: calc(.75em + 1.25em + 0.666666666667em);
    }

    input[type="number"] {
      width: 3em;
      min-width: 80px;
      padding-right: .5em;
      text-align: right;
    }

    .icon {
      position: absolute;
      top: 0;
      right: .5em;
      height: calc(.75em + 1.25em + 0.666666666667em);
      display: flex;
      cursor: pointer;
    }

    &.number {
      width: auto;
      display: inline-block;
      border-radius: 3px;
      border: 1px solid #AEA9A9;
      padding-right: .5em;
      background-color: white;

      input[type="number"] {
        background: transparent;
      }

      .unit {
        display: inline-block;
      }
    }
  }

}
